<template>
	<components-error v-if="error && error.code" :error="error"/>
	<div id="main" v-else :style="{backgroundColor:meta.background || '#fff'}">
		<div class="app-header"></div>
		<div class="app-container">
			<router-view :key="route_key"></router-view>
		</div>
		<div class="app-footer"></div>
	</div>
</template>
<script>
import ComponentsError from "@/components/views/Error"
import jsCookie from "js-cookie";
import axios from "axios";
import {getUrlParams} from "@/common/url";
import {isDebug} from "@/common/other";
export default {
	components:{ComponentsError},
	middleware:['views'],
	computed:{
		meta(){
			return this.$route.meta;
		},
		route_key(){
			return this.$route.fullPath
		},
		error(){
			return this.$store.state.views.error
		}
	},
	watch:{
		$route:{
			handler(n){
				this.$store.commit('views/setError',{});
			},
			immediate:true
		}
	},
	mounted(){
		this.checkLogin();
		this.initSource();
	},
	methods:{
		initSource(){
			var tc = getUrlParams('tc');
			if(tc){
				jsCookie.set('tc',tc)
			}
		},
		checkLogin(){
			if(isDebug()){
				jsCookie.set('globalUserId','"c76eed851a40220bd5f837c839f30c4e"');
				this.$store.commit('views/setUserInfo',{
					name:"张三"
				})
				this.$isResolve();
				return false;
			}
			var user_id = jsCookie.get('globalUserId');
			if(!user_id){
				return false;
			}
			user_id = user_id.replace(/"/g,'');
			axios.post("https://api.smarket.net.cn/customForm/checkRegistration",{
				customFormId: "2410101117496014",
				instanceId: 944027,
				tenantId: 100377,
				globalUserId:decodeURIComponent(user_id)
			}).then((res)=>{
				const form_data = res?.data?.body?.content?.formData;
				if(form_data){
					const user_info = {};
					form_data.map((item,index)=>{
						user_info[item.fieldName] = item.value;
					})
					this.$store.commit('views/setUserInfo',user_info);
					this.$isResolve();
				}
			})
		}
	}
}
</script>
<style lang="scss" >
@import '~@/assets/css/reset.scss';
#main{position: relative;margin: 0 auto; min-height: 100vh;display: flex;flex-direction: column;justify-content: space-between;
	.app-header{flex: 0 0 auto;}
	.app-container{position: relative; flex:1 0 auto;}
	.app-footer{flex: 0 0 auto;}
}
@media screen and (max-width: 1600px) {
	#main{zoom: .9;}
}
@media screen and (max-width: 1230px) {
	#main{zoom: 1;}
}
</style>
